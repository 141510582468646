<template>
  <v-container>
    <div class="header" style="">
      <span>
        <h3>Servidores Suporte</h3>
        <h4 style="color:red;">Obs: Os Cliente Equipotel / Eshotel e Appensina são os clientes que recebem as solicitações, se eles forem desativados, precisaremos reativa-los acessando o servidor</h4>
      </span>
    </div>
    <div style="border-radius:5px;margin-bottom:5px;display:flex;flex-direction:column;">
         <v-container class="">
            <v-row>

                    <v-col >
                        <v-select v-model="sistema" label="Sistema" item-text="nome" item-value="id" 
                            :items="listadesistema" outlined return-object hide-details dense
                            @change="limparComandoExibir()" >
                        </v-select>
                    </v-col>
                    <v-col >
                        <v-select v-model="comando" label="Comando" item-text="nome" item-value="id" 
                            :items="listadecomandos" outlined return-object hide-details dense
                            @change="limparComandoExibir()" >
                        </v-select>
                    </v-col>
                    <v-col >
                        <v-text-field type="text" error-count="" placeholder="" 
                        label="" append-icon=""  v-model="parametro" outlined color hide-details dense></v-text-field>
                        
                    </v-col>

            </v-row>

            <v-row style="margin-top:-10px;"> 

                    <v-col style="">
                        <v-row>
                        </v-row>
                    </v-col>

                    <v-col>
                        <v-row>
                            <v-col> 
                                <v-btn small elevation="" @click="() => setarComandoExibir()" color="success" block> Gerar Codigo</v-btn>       
                            </v-col>
                        </v-row>
                        
                    </v-col>
                    <v-col v-if="this.comandoExibir">
                        <v-row>
                            <v-col> 
                                <v-btn small elevation="" @click="() => executar()" color="info" block> {{this.comandoExibir}}</v-btn>       
                            </v-col>
                        </v-row>
                        
                    </v-col>

            </v-row>

          </v-container>
        
        
    </div>

     <v-container class="">
        <v-row>
          <v-col>

                <div v-html="dadosExibir"></div>

          </v-col>
        </v-row>
      </v-container>

  



  </v-container>
</template>

<script>

import SistemaServico from '@/services/sistema.service.js'
import axios from 'axios'

export default {
  name: 'ServidoresSuporte',
  components: {
    // HomePage
  },
  data(){
    return {
        dadosExibir:'',
        sistema:{id:1},
        listadesistema: [],
        parametro:'',
        listadecomandos:[
            {id:1,nome:'Listar Todos',comando:'pm2 list'},
            {id:2,nome:'Listar Especificos',comando:'pm2 list | grep -i '},
            {id:3,nome:'Pausar Todos',comando:'pm2 stop all'},
            {id:4,nome:'Pausar Especifico',comando:'pm2 stop'},
            {id:5,nome:'Reiniciar Todos',comando:'pm2 restart all'},
            {id:6,nome:'Reiniciar Especifico',comando:'pm2 restart'},
            {id:7,nome:'Desativar Jenkins',comando:'systemctl stop jenkins'},
            {id:8,nome:'Reiniciar Jenkins',comando:'systemctl restart jenkins'},
        ],
        comando:{id:1,nome:'Listar Todos',comando:'pm2 list'},
        comandoExibir:null
    }
  },
  methods: {
    async requisitarSistemas(){
        let sistemas = await SistemaServico.buscar({semimagem:1}).then(res => res.data)
        this.listadesistema = sistemas
        console.log('sistemas[0] ',sistemas[0])
        this.sistema = sistemas[0]
        console.log('this.listadesistema ',this.listadesistema)
    },
    setarComandoExibir(){
        let dados = {}
        if(this.comando.id == 2 || this.comando.id == 4 || this.comando.id == 6){
            if(!this.parametro || this.parametro.trim() == ''){
                this.parametro = 1
            }
            dados.comando = this.comando.comando+" "+this.parametro
        }else{
            dados = this.comando
        }

        this.comandoExibir = dados.comando
        
    },
    limparComandoExibir(){
        this.comandoExibir = null
        this.dadosExibir = null
    },
    executar(){
        let headers = {headers:{ "x-access-token":"sdr##%H3254gdfg$*76@5GFGB7634" }}
        let dados = {}
        if(this.comando.id == 2 || this.comando.id == 4 || this.comando.id == 6){
            if(!this.parametro || this.parametro == ''){
                this.parametro = 1
            }
            dados.comando = this.comando.comando+" "+this.parametro
        }else{
            dados = this.comando
        }

        console.log('dados.comando ',dados.comando)
        this.dadosExibir = null
        let url = ''
        if(this.sistema.id == 1){ url = 'https://apiappensina.fd.economysoftware.com.br/ambiente/comandosCentral' }
        if(this.sistema.id == 2){ url = 'https://apiequipotel.cd.economysoftware.com.br/ambiente/comandosCentral' }
        if(this.sistema.id == 3 || this.sistema.id == 5 || this.sistema.id == 6){ url = 'https://apieshotel.fdguest.economysoftware.com.br/usuarios/comandosCentral' }

        axios.post(url,dados,headers).then(resp=>{
            console.log('---')
            console.log('resp ',resp)
            let retorno = resp.data.msg.split('watching │')
            if(retorno.length > 1){
                retorno = resp.data.msg.split('watching │')[1]
            }else{
                retorno = resp.data.msg
            }
            
            let retorno2 = retorno.split('disabled │')
            let retornoFormatado = ''
            for(let i = 0,len = retorno2.length; i< len; i++){
                let linha = retorno2[i].split('│')
                console.log('retorno2[i] ',retorno2[i])
                console.log('linha',linha)
                if(linha[1]){
                    retornoFormatado += linha[1]+' - '+linha[2]+' - '+linha[9]+'<br>'
                }
            }
            this.dadosExibir = retornoFormatado
        }).catch(error =>{
            console.log('---')
            console.log(error)
        })
    }
  },
  async mounted(){
    await this.requisitarSistemas()
  }
}
</script>
